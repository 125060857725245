import React from 'react';
import { Helmet } from 'react-helmet';

function MainContainer(props: {
    children: React.ReactNode;
    indexPage?: boolean;
}) {
    return (
        <main
            className={
                'flex flex-col items-center ' +
                (props.indexPage ? 'justify-center ' : 'justify-start ') +
                'w-full min-h-screen box-border bg-gray-100 ' +
                (props.indexPage ? 'pb-8 sm:pb-12 ' : 'sm:pb-20 pb-32 ') +
                'pt-8 sm:pt-12 px-4 lg:px-10vw xl:px-20vw 2xl:px-25vw'
            }
        >
            <Helmet>
                <meta charSet='utf-8' />
                <html lang='en' />
                <title>Moritz Makowski | Developer Portfolio</title>
                <meta
                    name='description'
                    content='Computer Science/Engineering Science student at TUM. Developer by night.'
                />
            </Helmet>
            {props.children}
        </main>
    );
}

export default MainContainer;
